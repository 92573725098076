// Helper methods for rewards integration
// Sample activity id
// export const REWARDS_BUDGET_ONBOARDING_ACTIVITY_ID = '591dfe71-d19f-4f04-8c7a-6ec7899ddaf6';
import { hasAccessTokenExpired } from '@/util/authentication';

import { TRecord } from '@/types/common';

import { envOrDefault } from './env';

export const REWARDS_BUDGET_ONBOARDING_ACTIVITY_ID = 'fc256a06-1dad-4f8d-9196-e9a1638ac02f';
export const REWARDS_SP_RAINY_DAY_FUND_ONBOARDING_ACTIVITY_ID = '208fc4b7-68e7-471a-bd32-8d6100ed00fd';
export const REWARDS_SP_MONTHLY_DEPOSIT_ACTIVITY_ID = 'df4ae1a0-ad7b-4b0d-b46f-18649139e30b';

const IS_REWARDS_SAVINGS_PLANNER_ENABLED = Boolean(envOrDefault('REACT_APP_REWARDS_SAVINGS_PLANNER_ENABLED', false));
export enum RewardsActivity {
  BUDGET,
  SAVINGS_PLANNER,
}

export const REWARDS_ACTIONS = {
  BUDGET_ONBOARDING: 'rewards-budget-onboarding',
  BUDGET_MONTHLY_UPDATE: 'rewards-budget-monthly-update',
  SP_RAINY_DAY_ONBOARDING: 'rewards-sp-onboarding',
  SP_RAINY_DAY_MONTHLY_DEPOSIT: 'rewards-sp-monthly-deposit',
};

/**
 * Renders an activity badge on a page
 */
export const renderBadge = () => {
  window.dispatchEvent(new CustomEvent('badge-redraw'));
};

/**
 * Emits DOMContentLoaded event, it is sometimes required for properly setting up the
 * rewards integration
 */
export const emitDOMContentLoaded = () => {
  window.document.dispatchEvent(
    new Event('DOMContentLoaded', {
      bubbles: true,
      cancelable: true,
    })
  );
};

/**
 * Trigger the rewards activity completion event
 * this event is used when the badge available on a page
 */
export const completeBadgeActivity = () => {
  window.dispatchEvent(new CustomEvent('badge-activity-complete', { bubbles: true, detail: null }));
};

/**
 * Trigger the rewards loyalty deposit points, this method is used when the
 * badge is not available on the page
 * @param {*} userIdentifier - user identifier - corresponds to the AARP rewards user
 * @param {*} activityIdentifier  - unique identifier of the activity
 */
export const depositLoyaltyPoints = (userIdentifier, activityIdentifier) => {
  window.dispatchEvent(
    new CustomEvent('loyalty-deposit-points', {
      detail: {
        userIdentifier,
        activityIdentifier,
      },
    })
  );
};

export const isRewardsActivityEnabled = (activity: RewardsActivity): boolean => {
  switch (activity) {
    case RewardsActivity.SAVINGS_PLANNER:
      return IS_REWARDS_SAVINGS_PLANNER_ENABLED;
    default:
      return false;
  }
};

type ExtendedWindow = Window & typeof globalThis & { AARP: { store: { dispatch: (opt: TRecord<string>) => void } } };

/**
 * refresh the AARP rewards store
 */
export const refreshRewardsStore = () => {
  const aarpStore = (window as ExtendedWindow).AARP?.store;

  if (aarpStore) {
    aarpStore.dispatch({
      type: 'OAUTH_TOKEN_READ',
    });
  }
};

export const shouldForceLogin = () => {
  return hasAccessTokenExpired();
};
